<template>
  <div class="w3-row">
    <!-- BLOG VIEW -->
    <div  v-if="view_blog != null" class="w3-col l8 s12">
      <div class="w3-card-4 w3-margin w3-white" :id="view_blog.publishedAt">
        <img :src="'/images/' + view_blog.image + '.jpg'" alt="Blog Image" style="width:100%">
        <div class="w3-container">
          <p>&nbsp;</p>
          <h3><b>{{ view_blog.title }}</b></h3>
          <h5><span class="w3-opacity">{{ view_blog.publishedAt }}</span></h5>
          <h4><span class="w3-greyscale" v-html="view_blog.description">{{ view_blog.description }}</span></h4>
          <p>&nbsp;</p>
        </div>
        <div class="w3-container">
          <div class="w3-row">
            <div class="w3-col m8 s12">
              <p><button class="w3-button w3-padding-small w3-light-grey w3-border" @click="view_blog=null"><b>« {{ jw_label.home_page }}</b></button></p>
            </div>
            <div class="w3-col m4 w3-hide-small">
              <p><span class="w3-padding-small w3-right"><b>por </b> <span class="w3-tag">{{ view_blog.author }}</span></span></p>
            </div>
          </div>
          <div>
            <p>&nbsp;</p>
            <p v-html="blogBody(view_blog)"> {{ blogBody(view_blog) }} </p>
            <span class="w3-light-grey w3-medium" v-if="view_blog.hasOwnProperty('video')">
              <i><b>Vídeo:&nbsp;</b>&nbsp;<span class="jw">&nbsp;</span>&nbsp;<span class="jw">&nbsp;</span>&nbsp;<span class="jw">&nbsp;</span></i>
              <p v-for="(description, video) in view_blog.video" :key="video">
                <br />
                <iframe v-if="video.startsWith('TikTok')" width="340" height="700" :src="video.replace('TikTok', 'https://www.tiktok.com/embed/')" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe v-if="video.startsWith('Vimeo')" width="640" height="360" :src="video.replace('Vimeo', 'https://player.vimeo.com/video/')" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <br />
                <span v-html="description">{{ description }}</span>
              </p>
            </span>
          </div>
          <div class="w3-row">
            <div class="w3-col m8 s12">
              <p><button class="w3-button w3-padding-small w3-light-grey w3-border" @click="view_blog=null"><b>« {{ jw_label.home_page }}</b></button></p>
            </div>
            <div class="w3-col m4 w3-hide-small">
              <p><span class="w3-padding-small w3-right"><span class="w3-tag"><i>{{ view_blog.publishedAt }}</i></span></span></p>
            </div>
          </div>
        </div>
      </div>
      <hr>      
    </div>
    <!-- END BLOG VIEW -->

    <!-- Blog entries -->
    <div v-if="view_blog == null" class="w3-col l8 s12">
      <!-- BLOG INDEX BUTTONS -->
      <div class="w3-card w3-margin w3-white">
        <div class="w3-container">
          <p>
            <span v-for="yearIndex in ((new Date().getFullYear()) - 2012)" :key="yearIndex">
              <button :class="['w3-button w3-padding-small w3-border', classLoadedYear((new Date().getFullYear())-yearIndex+1)]" @click="gotoLoadedYear((new Date().getFullYear())-yearIndex+1)">
                {{ ((new Date().getFullYear())-yearIndex+1).toString() }}
              </button>&nbsp;
            </span>
            <span><button class="w3-button w3-padding-small w3-white w3-border" @click="scrollPageBottom()">&nbsp;»&nbsp;</button></span>
          </p>
        </div>
      </div>
      <!-- END BLOG INDEX BUTTONS -->

      <!-- Blog entry -->
      <div v-for="(blog, index) in blogs" :key="index">
        <div v-if="year_loaded[blog.publishedAt.substring(0,4)]" :id="blogIndexID(index)">
          <div class="w3-card-4 w3-margin w3-white" :id="blog.publishedAt">
            <img :src="'/images/' + blog.image + '.jpg'" alt="Blog Image" style="width:100%" v-if="reading_blogs.includes(blog.publishedAt)">
            <div class="w3-container">
              <p>&nbsp;</p>
              <h3 v-if="reading_blogs.includes(blog.publishedAt)"><b>{{ blog.title }}</b></h3>
              <h3 v-else><button class="w3-button w3-padding-small w3-white w3-border" @click="blogReading(blog)"><b>{{ blog.title }}</b></button></h3>
              <h5><span class="w3-opacity">{{ blog.publishedAt }}</span></h5>
              <h4 v-if="reading_blogs.includes(blog.publishedAt)"><span class="w3-greyscale" v-html="blog.description">{{ blog.description }}</span></h4>
              <p>&nbsp;</p>
            </div>
            <div class="w3-container" v-if="reading_blogs.includes(blog.publishedAt)">
              <div class="w3-row">
                <div class="w3-col m8 s12">
                  <p><button class="w3-button w3-padding-small w3-light-grey w3-border" @click="blogView(blog)"><b>{{blog.show ? '«' : jw_label.read_more + ' »'}}</b></button></p>
                </div>
                <div class="w3-col m4 w3-hide-small">
                  <p><span class="w3-padding-small w3-right"><b>por </b> <span class="w3-tag">{{ blog.author }}</span></span></p>
                </div>
              </div>
              <div v-if="blog.show">
                <p>&nbsp;</p>
                <p v-html="blogBody(blog)"> {{ blogBody(blog) }} </p>
                <span class="w3-light-grey w3-medium" v-if="blog.hasOwnProperty('video')">
                  <i><b>Vídeo:&nbsp;</b>&nbsp;<span class="jw">&nbsp;</span>&nbsp;<span class="jw">&nbsp;</span>&nbsp;<span class="jw">&nbsp;</span></i>
                  <p v-for="(description, video) in blog.video" :key="video">
                    <br />
                    <iframe v-if="video.startsWith('TikTok')" width="340" height="700" :src="video.replace('TikTok', 'https://www.tiktok.com/embed/')" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe v-if="video.startsWith('Vimeo')" width="640" height="360" :src="video.replace('Vimeo', 'https://player.vimeo.com/video/')" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    <br />
                    <span v-html="description">{{ description }}</span>
                  </p>
                </span>
              </div>
            </div>
          </div>
          <hr>      
        </div>
      </div>

      <!-- BLOG INDEX BUTTONS -->
      <div class="w3-card w3-margin w3-white">
        <div class="w3-container">
          <p>
            <span><button class="w3-button w3-padding-small w3-white w3-border" @click="scrollPageTop()">&nbsp;«&nbsp;</button></span>
            <span v-for="yearIndex in ((new Date().getFullYear()) - 2012)" :key="yearIndex">
              &nbsp;
              <button :class="['w3-button w3-padding-small w3-border', classLoadedYear((new Date().getFullYear())-yearIndex+1)]" @click="gotoLoadedYear((new Date().getFullYear())-yearIndex+1)">
                {{ ((new Date().getFullYear())-yearIndex+1).toString() }}
              </button>
            </span>
          </p>
        </div>
      </div>
      <!-- END BLOG INDEX BUTTONS -->
    </div>
    <!-- END BLOG ENTRIES -->

    <!-- Right side panel -->
    <div class="w3-col l4">
      <!-- About Site -->
      <div class="w3-card w3-margin w3-margin-top">
        <img src="/images/log.jpg" style="width:100%" />
        <div v-if="is_guest" class="w3-container w3-padding">
          <h4 ><b><i>{{ jw_label.welcome }} !</i></b></h4>
          <p><b-button block type="submit" variant="light" @click="is_guest=false">Login</b-button></p>
        </div>
        <div v-else class="w3-container w3-white">
          <hr />
          <h4 v-show="is_login"><b><i>{{ jw_label.welcome }} !</i></b></h4>
          <p v-show="!is_login"><b-form-input id="email" type="email" v-model="email" :state="isValidEmail" :placeholder="jw_label.email"></b-form-input></p>
          <p v-show="!is_login"><b-form-input id="password" type="password" v-model="password" :state="isValidPassword" :placeholder="jw_label.password"></b-form-input></p>
          <p v-show="!is_login"><b-button block type="submit" variant="dark" :disabled="!isValidEmail||!isValidPassword" @click="is_login=true">Login</b-button></p>
        </div>
      </div>
      <hr /> 
      
      <!-- Login -->
      <div class="w3-card w3-margin">
        <div class="w3-container w3-padding">
          <h4><b>{{ jw_label.site_path }}</b></h4>
        </div>
        <div class="w3-container w3-white">
          <p>2021 - 5{{ jw_label.version }} (VueJS, jQuery, html, css)<br />{{ jw_label.hosted }} <a href="https://www.ionos.ca/" target="_blank">IONOS</a></p>
          <p>2019 - 4{{ jw_label.version }} (VueJS, jQuery, html, css)<br />{{ jw_label.hosted }} <a href="https://www.github.com/" target="_blank">GitHub</a> {{ jw_label.and }} <a href="https://www.zeit.co/" target="_blank">ZEIT</a></p>
          <p>2014 - 3{{ jw_label.version }} (php, jQuery, bootstrap, html, css)<br />{{ jw_label.hosted }} <a href="https://www.ipage.com/" target="_blank">iPage</a></p>
          <p>2013 - 2{{ jw_label.version }} (ruby on rails, html, css)</p>
          <p>2013 - 1{{ jw_label.version }} (html, css)<br />{{ jw_label.hosted }} <a href="https://www.bluehost.com/" target="_blank">bluehost</a></p>
          <p>2012 - {{ jw_label.domain_registered }}</p>
        </div>
      </div>
      <hr />
      
      <!-- Reading Posts -->
      <div class="w3-card w3-margin">
        <div class="w3-container w3-padding">
          <h4>{{ jw_label.reading_articles }}</h4>
        </div>
        <ul class="w3-ul w3-hoverable w3-white" v-for="blog in blogs" :key="blog.publishedAt">
          <li class="w3-padding-16"  v-if="reading_blogs.includes(blog.publishedAt)">
            <img :src="'/images/' + blog.image + '.jpg'" alt="Image" class="w3-left w3-margin-right" style="width:50px" />
            <span>{{ blog.publishedAt }}</span><br />
            <button class="w3-tag w3-light-grey w3-padding-large w3-border" @click="blogView(blog)">{{ blog.title }}</button><br />
          </li>
        </ul>
      </div>
      <hr />
      
      <!-- Archives -->
      <div class="w3-card w3-margin">
        <div class="w3-container w3-padding">
          <h4>{{ jw_label.archives }}</h4>
        </div>
        <div class="w3-container w3-white">
          <div v-for="yearIndex in ((new Date().getFullYear()) - 2012)" :key="yearIndex">
            <span class="w3-tag w3-white w3-large w3-margin-top">
              <button class="w3-button w3-padding-small w3-white w3-border" @click="loadJsonFiles((new Date().getFullYear())-yearIndex+1)">
                <span class="jw">&nbsp;</span>{{ ((new Date().getFullYear())-yearIndex+1).toString() + ' ' + (year_loaded[((new Date().getFullYear())-yearIndex+1).toString()] ? '«' : '»') }}
              </button>
            </span> 
            <div class="w3-white">
              <div v-for="blog in blogs" :key="blog.publishedAt">
                <span class="w3-white w3-medium jw-jw" v-if="blog.publishedAt.startsWith(((new Date().getFullYear())-yearIndex+1).toString())">
                  <button class="w3-tag w3-light-grey w3-border" @click="blogView(blog)">{{ blog.publishedAt }}<span class="jw-wj">&nbsp;</span>{{ blog.title }}</button><br />
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <!-- END Right side panel -->
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'JW',
  props: {
    jw_label: Object
  },
  data() {
    return {
      is_guest: true,
      is_login: false,
      email: '',
      password: '',
      blogs: [],
      year_loaded: {},
      view_blog: null,
      reading_blogs: [
        '2021-07-23',
        '2021-06-01'
      ]
    }
  },
  computed: {
    isValidEmail () {
      return (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(this.email);
    },
    isValidPassword () {
      return (/^.*(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*?]).*$/).test(this.password);
    }
  },
  mounted: function(){
    for (let year = (new Date().getFullYear()); year > 2012; year--) this.year_loaded[year.toString()] = false;
    this.loadJsonFiles(new Date().getFullYear());
    this.loadJsonFiles((new Date().getFullYear()) - 1);
    if (window.location.search.substring(1, 9) < (new Date().getFullYear()).toString() && window.location.search.substring(1, 9) > "2013") this.loadJsonFiles(Number(window.location.search.substring(1, 5)));
  },
  methods: {
    loadJsonFiles: function (year) {
      if (!this.year_loaded[year.toString()]) {
        let self = this;
        $.getJSON('/json/' + year.toString() + '.json').then(function(data) {
          for (let blog of data) {
            self.blogs.push(blog);
            if (window.location.search.substring(1, 9) == blog.publishedAt.split('-').join('')) self.view_blog = blog;
          }
          self.blogs = self.blogs.sort(function(a,b){return a.publishedAt>b.publishedAt?-1:(a.publishedAt<b.publishedAt?1:0);})
          self.year_loaded[year.toString()] = true;
        });
      }
    },
    gotoLoadedYear: function (year) {
      this.loadJsonFiles(year);      
      document.location = '#' + year.toString();
    },
    classLoadedYear: function (year) {
      return (this.year_loaded[year.toString()] ? 'w3-white' : 'w3-grey');
    },
    blogIndexID: function (index) {
      if ((index == 0) || (index < this.blogs.length && this.blogs[index].publishedAt.substring(0, 4) != this.blogs[index - 1].publishedAt.substring(0, 4))) return this.blogs[index].publishedAt.substring(0, 4);
      return '';
    },
    blogBody: function (blog) {
      var blog_body = "";
      blog.body.forEach( function(line) {
        if (window.location.host.toLowerCase().endsWith('fan.ca') || window.location.host.toLowerCase().endsWith('wj9.org')) {
          if (line.indexOf('data-pa') < 0) blog_body += line.replace("<jw-img", "<img style='width:100%'").replace("data-po='", "src='/images/jw/") + "<br />";
          else blog_body += line.replace("<jw-img", "<img style='width:100%'").replace("data-pa-", "src='https://cdn").replace("='jw/", ".picsart.com/") + "<br />";
        }
        else blog_body += line.replace("<jw-img", "<img style='width:100%'").replace("data-pi='", "src='https://i.pinimg.com/originals/") + "<br />";
      });
      var oyid = '';
      var nyid = '';
      if (blog_body.indexOf("/embed/") > 0 && this.password != null && this.password.length > 5 && this.email != null && this.email.length > 6) {
        var yid = blog_body.substring(blog_body.indexOf("/embed/") + 7, blog_body.indexOf("/embed/") + 18).split('');
        var pe = (this.password.substring(0, 5) + this.email.substring(0, 6)).split('');
        for (let i = 0; i < yid.length; i++) yid[i] = String.fromCharCode(yid[i].charCodeAt(0) + pe[i].charCodeAt(0) % 10);
        oyid = blog_body.substring(blog_body.indexOf("/embed/"), blog_body.indexOf("/embed/") + 18);
        nyid = "/embed/" + yid.join('');
      }
      return blog_body.replaceAll("fotografia='", "src='/jw/" + this.getEmailPassword(this.password + this.email) + "=").replaceAll(oyid, nyid);
    },
    blogView: function (blog) {
      this.blogReading(blog);
      this.view_blog = blog
      window.gtag('event', 'ReadMore', { event_category: 'Blog', event_label: blog.publishedAt, value: '1'});
      $('html, body').animate({scrollTop: 700}, 'fast');
    },
    blogReading: function (blog) {
      if (!this.reading_blogs.includes(blog.publishedAt)) this.reading_blogs.push(blog.publishedAt);
    },
    getEmailPassword: function (email_password) {
      if (this.is_login && email_password) {
        const a0 = Array.from('~!@#$%^&*.');
        var a1 = Array.from(email_password);
        for (let i = 0; i < a1.length; i++) {
          for (let j = 0; j < a0.length; j++) {
            if (a1[i] == a0[j]) a1[i] = j.toString();
          }
        }
        email_password = a1.join('');
      } else email_password = "wj";
      if (email_password.indexOf('?') < 0) email_password = '?' + email_password;
      return email_password;
    },
    scrollPageTop: function () {
      $('html, body').animate({scrollTop: 0}, 'fast');
    },
    scrollPageBottom: function () {
      $('html, body').animate({scrollTop: document.body.scrollHeight}, 'fast');
    }
  }  
}
</script>
