<template>
  <div id="app">
    <div class="w3-content" style="max-width:1400px">
      <header class="jw-header-bg w3-container w3-center w3-padding-32">
        <h6><b>&nbsp;</b></h6>
        <a href="/"><img src="/images/logo.png" alt="J & W" /></a>
        <h1><b>&nbsp;</b></h1>
        <p><span class="w3-tag"><a href="http://jfan.ca">jfan.ca</a></span>&nbsp;|&nbsp;<span class="w3-tag"><a href="http://wzhao.ca">wzhao.ca</a></span></p>
      </header>
      <JW :jw_label="jw_label" />
      <br />
    </div>
    <footer class="w3-container w3-dark-grey w3-padding-32 w3-margin-top">
      <a href="http://jfan.ca" :class="['w3-tag w3-round w3-black w3-padding-large w3-margin-bottom', classLocationHostJFan]">« jfan »</a>
      <a href="http://wzhao.ca" :class="['w3-tag w3-round w3-black w3-padding-large w3-margin-bottom', classLocationHostWZhao]">« wzhao »</a>
      <p>&copy; {{ jw_label.copyright }} 2012-{{ (new Date().getFullYear()).toString() }}&nbsp;&nbsp;<a href="/">J&W</a>&nbsp;&nbsp;|&nbsp;&nbsp;CSS {{ jw_label.designed }} por <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">w3.css</a></p>
    </footer>
  </div>
</template>

<script>

import JW from './components/JW.vue';

export default {
  name: 'App',
  components: {
    JW
  },
  data() {
    return {
      jw_label: {
        welcome: 'Bienvenido',
        home_page: 'Página principal',
        read_more: 'leer más ...',
        email: 'Correo electrónico',
        password: 'Contraseña',
        copyright: 'Derechos de autor',
        designed: 'diseñado',
        site_path: 'Ruta de acceso del sitio web',
        version: 'ª versión',
        hosted: 'alojado en',
        domain_registered: 'Dominio registrado',
        reading_articles: 'Leer artículos',
        archives: 'Archivos',
        and: 'y'
      }
    }
  },
  computed: {
    classLocationHostJFan: function () {
      return {
        'w3-disabled': window.location.host.toLowerCase().endsWith('jfan.ca')
      }
    },
    classLocationHostWZhao: function () {
      return {
        'w3-disabled': window.location.host.toLowerCase().endsWith('wzhao.ca')
      }
    }
  },
  mounted: function(){
    if (window.location.host.toLowerCase().endsWith('fan.ca')) {
      this.jw_label.welcome = 'Bem-vindo';
      this.jw_label.home_page = 'Página inicial';
      this.jw_label.read_more = 'ler mais...';
      this.jw_label.email = 'Correio eletrónico';
      this.jw_label.password = 'Senha';
      this.jw_label.copyright = 'Direitos autorais';
      this.jw_label.designed = 'projetado';
      this.jw_label.site_path = 'Caminho do site';
      this.jw_label.version = 'ª versão';
      this.jw_label.hosted = 'hospedado em';
      this.jw_label.domain_registered = 'Domínio registado';
      this.jw_label.reading_articles = 'Artigos de leitura';
      this.jw_label.archives = 'Arquivos';
      this.jw_label.and = 'e';
    } 
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700');
</style>